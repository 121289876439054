<template>
  <div class="search">
    <div>
      <span style="font-size: 40px"> {{$t('function.menu.search')}} - {{ search.name }} </span>
      <br>
      <br>
      <span>{{$t('function.menu.search')}}</span>
      <br>
      <br>
<!--      关键字-->
      <el-input style="width: 400px;margin-right: 20px" v-model="search.a" :placeholder="$t('keywords')"></el-input>
      <el-select style="width: 400px;margin-right: 20px" v-model="search.value" :placeholder="$t('Classification')">
<!--        分类-->
        <el-option  v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
<!--      在子类别中搜索-->
      <el-checkbox v-model="search.c">{{$t('hint.4')}}</el-checkbox>
      <br>
<!--      按产品描述搜索-->
      <el-checkbox v-model="search.d">{{$t('hint.5')}}</el-checkbox>
      <br>
      <br>
      <!--      搜索-->
      <el-button size="mini">{{$t('function.menu.search')}}</el-button>
      <br>
<!--      搜索-->
      <span style="font-size: 30px;margin-bottom: 10px;display: inline-block">{{$t('function.menu.search')}}</span>
      <br>
      <div class="btn_contrast_screen">
        <div class="btn_contrast_screen_1" style="float: left">
            <el-button-group style="margin-top: 10px; margin-right: 10px">
              <el-button type="primary" :plain="ProductPicture !== true" size="small" :title="$t('list')" icon="el-icon-s-unfold" @click="ProductPicture = true"></el-button>
              <el-button type="primary" :plain="ProductPicture !== false"  size="small" :title="$t('grid')" icon="el-icon-menu" @click="ProductPicture = false"></el-button>
            </el-button-group>
<!--          <el-button icon="el-icon-s-unfold" size="mini" @click="list_btn" title="列表"></el-button>-->
<!--          <el-button icon="el-icon-s-grid" size="mini" @click="grid_btn" title="网格"></el-button>-->
        </div>
        <div class="btn_contrast_screen_2">
<!--          对比产品-->
          <span style="color: #1791e6;margin-left: 40px">{{$t('Compare.products')}}({{search.num}})</span>
        </div>
        <div class="btn_contrast_screen_3">
          <el-form label-width="130px">
            <el-col :span="12">
<!--              排序-->
              <el-form-item :label="$t('column.headercell.tooltip.sort')">
                <el-select size="mini" v-model="search.value" :placeholder="$t('sys.Bydefault')">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
<!--              显示-->
              <el-form-item :label="$t('fibank.status.view')">
                <el-select size="mini" v-model="search.value" :placeholder="$t('sys.Bydefault')">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
      </div>
      <el-col v-loading="listloading">
        <div v-if="this.list.length === 0 ">
          <p>{{$t('hint.6')}}</p>
        </div>
        <div v-else >
            <div v-show="ProductPicture=== true " class="list_img_btn" v-for="v in list" :key="v.id">
      <!--        图片-->
              <div class="list_img" style="width: 200px;height: 200px">
<!--                <img @click="clickimg(v)" style="width: 200px;height: 200px" :src="v.pictureUrl" alt="">-->
                <el-image
                    :src="v.pictureUrl"
                    fit="contain"
                    @click="clickimg(v)"
                    style="width: 200px;height: 200px"
                >
                </el-image>
              </div>
              <div class="list_btn">
      <!--          名称-->
                <span style="font-size: 18px;font-weight: 600;margin-top: 10px;display: inline-block">{{ $t(v.goodsName) }}</span><br>
      <!--          简介-->
                <span style="display: inline-block;font-weight: 300;margin-top: 10px">{{ v.kindName }}</span><br>
      <!--          价格-->
                <span style="display: inline-block;margin: 10px 20px 0 0;font-size: 24px">{{ v.standardPrice }}.00 $
                  <span v-if="v.standardPv" style="color: rgb(153, 153, 153);font-size: 16px;text-decoration: line-through">{{v.standardPv}}.00 $</span></span><br>
                <span style="color: rgb(153, 153, 153)">{{ v.d }}</span><br>
                <div style="margin-top: 30px">
                  <el-button round type="warning" plain icon="el-icon-shopping-cart-2" @click="getGoods(v)">{{$t('common.buy')}}</el-button>
  <!--                <el-button round type="warning" plain style="width: 200px" :title="$t('add.bookmark')">❤</el-button>-->
  <!--                <el-button round type="warning" plain style="width: 200px" :title="$t('comparing')" icon="el-icon-sort"></el-button>-->
                </div>
              </div>
            </div>
            <div v-show="ProductPicture=== false " class="grid_img_btn">
              <div v-for="v in list" :key="v.id">
                <div style="width: 200px;height: 200px;margin:0 auto" class="grid_img">
<!--                  <img  style="width: 200px;height: 200px;" :src="v.imgsrc" alt="">-->
                                  <el-image
                                      :src="v.imgsrc"
                                      fit="contain"
                                      @click="clickimg(v)"
                                      style="width: 100%;height: 100%"
                                  >
                                  </el-image>
                </div>
                <div class="grid_text">
                  <span style="font-size: 18px;font-weight: 600;margin-top: 10px;display: inline-block">{{ $t(v.goodsName) }}</span><br>
                  <span style="display: inline-block;font-weight: 300;margin-top: 10px">{{ v.kindName }}</span><br>
                  <span style="display: inline-block;margin: 10px 20px 0 0;font-size: 24px">{{ v.standardPrice }}.00 $
                  <span v-if="v.proPrice" style="color: rgb(153, 153, 153);font-size: 16px;text-decoration: line-through">{{v.proPrice}}.00 $</span></span><br>
                  <span style="color: rgb(153, 153, 153)">{{ v.d }}</span><br>
                </div>
                <div class="grid_btn" style="margin-top: 30px">
                  <el-button round type="warning" plain icon="el-icon-shopping-cart-2" @click="getGoods(v)">{{$t('common.buy')}}</el-button>
  <!--                <el-button round type="warning" plain :title="$t('添加书签')">❤</el-button>-->
  <!--                <el-button round type="warning" plain :title="$t('进行对比')" icon="el-icon-sort"></el-button>-->
                </div>
              </div>
            </div>
            <div style="float: right">
<!--              <p>{{$t('fibank.status.view')}}从 {{ProductPicture}} {{$t('label.to')}} {{ProductPicture}} {{this.$t('hint.From')}} {{ProductPicture}} ({{$t('memberorder.mode0')}} {{ProductPicture}} {{$t('page')}})</p>-->
            </div>
        </div>
      </el-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {getMessagerByCode} from "@/api/rus/api";

export default {
  name: "SearchDetails",
  data(){
    return{
      search:{},
      options:[
        {
          value: '1',
          label: '11'
        }, {
          value: '2',
          label: '22'
        }, {
          value: '3',
          label: '33'
        }
      ],
      ProductPicture:false,
      goodsCode:null,
      listloading:false,
      list:[]
    }
  },
  computed:{
    ...mapGetters([
      'shoppingList',
      'language',
      'userCode',
      'ckCode',
      'goodsKindCode',
      'goodsKindName',
      'username',
      'isAgent',
      'permissions',
      'permission_routes'
    ]),
  },
  created() {
    this.goodsCode = this.$route.query.searchInput
    this.getGood()
  },
  methods:{
    getGood(){
      this.listloading = true
      getMessagerByCode({'companyCode': this.ckCode, 'goodsCode': this.goodsCode}).then(res=>{
        console.log(res)
        this.list = res.data
          for (let a = 0; a < this.list.length; a++) {
              if (this.list[a].imgsrc.indexOf(';') !== -1) {
                  this.list[a].imgsrc = this.list[a].imgsrc.split(';')[0]
              } else {
                  this.list[a].imgsrc = [this.list[a].imgsrc][0]
              }
          }
        this.listloading = false
      })
    },
    getGoods(item) {
      this.$confirm(this.$t('AddGoodsToShoppingCart').toString(), '', {
        confirmButtonText: this.$t('Confirm.Order'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox'
      }).then(() => {
        item.quantity = 1
        this.$store.dispatch('shopping/addShoppings', JSON.parse(JSON.stringify(item)))
        this.$message({
          type: 'success',
          message: this.$t('bigoods.addcart.success')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('po.status.3')
        })
      })
    },
    clickimg(v){
      this.$router.push({path: "/goodsdetail", query: {...v,isDoing:'false'}})
    },
    list_btn(){
      this.ProductPicture = true
      console.log(this.ProductPicture)
    },
    grid_btn(){
      this.ProductPicture = false
      console.log(this.ProductPicture)
    }
  }
}
</script>

<style scoped>
.search{
  text-align: left;
}
.btn_contrast_screen{
  display: flex;
}
.btn_contrast_screen_1{
  flex: 1;
}
.btn_contrast_screen_2{
  flex: 1;
}
.btn_contrast_screen_3{
  flex: 2;
}
.list_img_btn{
  width: 95%;
  margin: 0 auto 20px;
  display: flex;
  /*box-sizing: border-box;*/
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px rgb(221, 221, 221);
}
.list_img_btn:hover{
  -moz-box-shadow:0px 1px 13px #757575; -webkit-box-shadow:0px 1px 13px #757575; box-shadow:0px 1px 13px #757575;
}
.list_img{
  flex: 1;
}
.list_btn{
  flex:4;
}
.grid_img_btn{
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}
.grid_img_btn>div{
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
  /*float: left;*/
  border: solid 1px rgb(221, 221, 221);
  width: 23%;
  margin: 10px 0;
  padding:15px 10px;
}
.grid_img_btn>div:hover{
  -moz-box-shadow:0px 1px 13px #757575; -webkit-box-shadow:0px 1px 13px #757575; box-shadow:0px 1px 13px #757575;
}
</style>
